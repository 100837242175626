<template>
  <div class="fill-height overflow-y-auto">
    <div class="mx-auto" style="width: 640px; max-width: 640px;">
      <div class="d-flex flex-row align-center mt-2">
        <div class="subtitle-1" style="width: 100px">Пароль</div>
        <v-text-field
            v-model="passwordEdit"
            :rules="rulesPassword"
            :type="isShowPassword ? 'text' : 'password'"
            :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="isShowPassword = !isShowPassword"
            outlined
            dense
            lang="ru"
            hide-details
            ref="password"
        />
      </div>
      <v-btn depressed color="primary" block @click="save" class="my-4">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputPassword',
  data: () => ({
    passwordEdit: '',
    isShowPassword: false,
  }),
  computed: {
    rulesPassword() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
  },
  methods: {
    save() {
      this.$refs.password.validate();
      if (!this.$refs.password.valid) {
        return;
      }
      this.$emit('save', this.passwordEdit);
    },
  },
};
</script>


<style scoped>

</style>
