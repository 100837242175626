import { render, staticRenderFns } from "./InputCompany.vue?vue&type=template&id=de27bd34&scoped=true&"
import script from "./InputCompany.vue?vue&type=script&lang=js&"
export * from "./InputCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de27bd34",
  null
  
)

export default component.exports