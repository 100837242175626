<template>
  <v-dialog
    :value="value"
    @change="v => $emit('input', v)"
    scrollable
    persistent
    :max-width="maxWidth"
  >
<!--    :transition="transition"-->
    <v-card tile class="app-dialog">
      <v-toolbar
        flat
        class="toolbar"
        dense
        v-if="hasToolbar"
      >
        <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
        <v-spacer/>
        <slot name="toolbar"></slot>
        <v-btn
          icon
          @click="$emit('input', false)"
          class="toolbar__button-close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ViewDialog',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    transition: {
      type: String,
      required: false,
      default: null, //'dialog-bottom-transition',
    },
    hasToolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 680,
    }
  },
};
</script>

<style scoped>
.toolbar {
  flex-grow: 0;
}
.toolbar__button-close {
  margin-right: 0 !important;
}
</style>
