<script>
import CardUser from "@/ui/components/CardUser.vue";
import InputPassword from "@/ui/components/InputPassword.vue";
import InputUser from "@/ui/components/InputUser.vue";
import ViewDialog from "@/ui/components/ViewDialog.vue";

export default {
  name: "ViewUsers",
  components: {ViewDialog, InputUser, InputPassword, CardUser},
  props: ['companyId', 'isSuper'],
  data: () => ({
    users: null,
    currentUser: null,

    isShowUserAdd: false,
    isShowUserEdit: false,
    isShowPasswordEditor: false,

    userEdit: null,

    companies: null,
    company: null,

    companyModel: null,
  }),
  computed: {
    userId() {
      return this.$store.persist.d.userId;
    },
    usersFiltered() {
      if (!this.users?.length) {
        return null;
      }
      return this.users?.filter(user => {
        if (this.companyId) {
          return user.company_id === this.companyId;
        }
        // by company id
        if (this.companies && this.companyModel) {
          return user.company_id === this.companyModel;
        }
        return true;
      })
    },
  },
  methods: {
    startCreateUser() {
      this.isShowUserAdd = true;
      this.userEdit = {
        name: '',
        login: '',
        password: '',
        companyId: null,
        role: 'user',
        llm_runs_limits_count: -1,
      };

      if (this.company?._freeLLMLimits !== undefined && this.$store.persist.d.role === 'admin') {
        this.userEdit._freeLLMLimits = this.company._freeLLMLimits;
        // } else if (this.companies?.length && (this.$store.persist.d.role === 'super' || this.$store.persist.d.role === 'dev')) {
        //   this.userEdit._freeLLMLimits = this.companies.find(company => company.id === this.userEdit.company_id)?._freeLLMLimits || 0;
        // } else {
        //   this.userEdit._freeLLMLimits = 0;
        // }

      } else {
        this.userEdit._freeLLMLimits = undefined;
      }
    },
    startUpdateUser(user) {
      this.isShowUserEdit = true;
      this.userEdit = {...user};

      // this.userEdit._freeLLMLimits = -1;
      // if (this.company?._freeLLMLimits !== undefined && this.$store.persist.d.role === 'admin') {
      //   this.userEdit._freeLLMLimits = this.company._freeLLMLimits;
      // } else if (this.companies?.length && (this.$store.persist.d.role === 'super' || this.$store.persist.d.role === 'dev')) {
      //   this.userEdit._freeLLMLimits = this.companies.find(company => company.id === this.userEdit.company_id)?._freeLLMLimits || 0;
      // } else {
      //   this.userEdit._freeLLMLimits = 0;
      // }
    },
    startUpdatePassword(user) {
      this.isShowPasswordEditor = true;
      this.userEdit = {...user};
      this.$refs.InputPassword.passwordEdit = '';
    },


    async createUser(user) {
      this.userEdit.login = user.login;
      this.userEdit.password = user.password;
      this.userEdit.name = user.name;
      this.userEdit.role = user.role;
      this.userEdit.llm_runs_limits_count = user.llm_runs_limits_count;

      let companyId = user.company_id || null;
      // current company if admin role
      if (!this.isSuper && this.company?.id) {
        companyId = this.company.id;
        this.userEdit.company_id = companyId;
      }

      const response = await this.$api.main.postRequest('models/user', {
        login: this.userEdit.login,
        password: this.userEdit.password,
        name: this.userEdit.name,
        role: this.userEdit.role,
        company_id: companyId,
        llm_runs_limits_count: this.userEdit.llm_runs_limits_count,
      });
      if (!response.success) {
        if (response.error === 'exist login') {
          this.$api.app.snackError('Пользователь с таким логином уже существует');
          return;
        }
      }
      await this.init(true);
      this.isShowUserAdd = false;
    },
    async updateUser(user) {
      this.userEdit.id = user.id;
      this.userEdit.login = user.login;
      this.userEdit.password = user.password;
      this.userEdit.name = user.name;
      this.userEdit.role = user.role;
      this.userEdit.llm_runs_limits_count = user.llm_runs_limits_count;

      let companyId = user.company_id || null;
      // current company if admin role
      if (!this.isSuper && this.company?.id) {
        companyId = this.company.id;
        this.userEdit.company_id = companyId;
      }

      const response = await this.$api.main.postRequest('models/user', {
        id: this.userEdit.id,
        login: this.userEdit.login,
        name: this.userEdit.name,
        role: this.userEdit.role,
        company_id: companyId,
        llm_runs_limits_count: this.userEdit.llm_runs_limits_count,
      });
      if (!response.success) {
        if (response.error === 'exist login') {
          this.$api.app.snackError('Пользователь с таким логином уже существует');
          return;
        }
      }
      await this.init(true);
      this.isShowUserEdit = false;
    },
    deleteUser(user) {
      this.$api.app.openConfirmDialog({
        title: 'Удаление пользователя',
        text: 'Вы уверены, что хотите удалить этого пользователя?',
        ok: async () => {
          await this.$api.main.deleteRequest('models/user', {id: user.id});
          await this.init(true);
        },
      });
    },
    async savePassword(newPassword) {
      await this.$api.main.postRequest('models/user', {
        id: this.userEdit.id,
        password: newPassword,
      });
      await this.init();
      this.isShowPasswordEditor = false;
    },

    async fetchUsers() {
      // TODO: loading and error
      this.users = (await this.$api.main.getRequest('models/users')).data;
      if (['super', 'dev'].includes(this.$store.persist.d.role)) {
        await this.fetchCompanies();
        this.users = this.users.map(user => ({
          ...user,
          company:
            user.company_id ?
              this.companies.find(company => company.id === user.company_id) :
              null,
        }));
      }
    },

    async fetchCompanies() {
      // TODO: loading and error
      this.companies = (await this.$api.main.getRequest('models/companies')).data.map(el => ({
        ...el,
        _freeLLMLimits: null,
      }));
    },

    async fetchCompany() {
      this.company = (await this.$api.main.getRequest('models/company/my')).data;
      this.company._freeLLMLimits = null;
    },

    async init(needUpdate = false) {
      await this.fetchUsers();

      if (this.isSuper) {
        await this.fetchCompanies();
      } else {
        await this.fetchCompany();
      }

      if (this.users && this.companies) {
        this.companies = this.companies.map(company => {

          let freeLLMLimits = company.llm_runs_limits_count;

          if (freeLLMLimits === -1) {
            company._freeLLMLimits = -1;
            return company;
          }

          if (freeLLMLimits === 0) {
            company._freeLLMLimits = 0;
            return company;
          }

          this.users.forEach(user => {
            if (user.company_id !== company.id) {
              return;
            }

            if (user.llm_runs_limits_count > 0) {
              freeLLMLimits -= user.llm_runs_limits_count;
            }
          });

          if (freeLLMLimits < 0) {
            freeLLMLimits = 0;
          }

          company._freeLLMLimits = freeLLMLimits;
          return company;
        });

        this.users = this.users.map(user => {
          const company = this.companies.find(company => company.id === user.company_id);
          if (company) {
            user._freeLLMLimits = company._freeLLMLimits;
          }
          return user;
        });

      } else if (this.users && this.company) {

        this.company._freeLLMLimits = this.company.llm_runs_limits_count;

        if (this.company._freeLLMLimits === -1) {
          return;
        }

        if (this.company._freeLLMLimits === 0) {
          return;
        }

        this.users.forEach(user => {
          if (user.llm_runs_limits_count > 0) {
            this.company._freeLLMLimits -= user.llm_runs_limits_count;
          }
        });

        if (this.company._freeLLMLimits < 0) {
          this.company._freeLLMLimits = 0;
        }

        this.users = this.users.map(user => {
          user._freeLLMLimits = this.company._freeLLMLimits;
          return user;
        });
      }

      if (needUpdate) {
        this.$emit('update');
      }
    },
  },
  async created() {
    await this.init();
  },
}
</script>

<template>
  <div class="mb-4">
    <div class="d-flex justify-space-between align-center">
      <h3>Пользователи</h3>
      <v-spacer/>
      <v-btn depressed @click="startCreateUser">
        Добавить
      </v-btn>
    </div>

    <div v-if="!companyId && ($store.persist.d.role === 'super' || $store.persist.d.role === 'dev')">
      <v-autocomplete
        v-model="companyModel"
        label="Компания"
        dense
        outlined
        :items="companies"
        item-text="name"
        item-value="id"
        hide-details
        clearable
        class="my-2"
      />
    </div>

    <template v-if="usersFiltered && usersFiltered.length">
      <CardUser
        v-for="user of usersFiltered"
        :key="user.id"
        :name="user.name"
        :login="user.login"
        :role="user.role"
        :companyName="user.company?.name"
        :createdAt="user.createdAt"
        :updatedAt="user.updatedAt"
        :id="user.id"
        :item="user"
        :company="company"
        @update="startUpdateUser(user)"
        @updatePassword="startUpdatePassword(user)"
        @delete="deleteUser(user)"
      />
    </template>

    <div v-else>Пользователи отсутствуют</div>

    <ViewDialog v-model="isShowUserAdd" title="Добавление пользователя">
      <InputUser :user="userEdit" @save="createUser" :companies="companies" :isSuper="isSuper"/>
    </ViewDialog>

    <ViewDialog v-model="isShowUserEdit" title="Редактирование пользователя">
      <InputUser :user="userEdit" :isEdit="true" @save="updateUser" :companies="companies" :isSuper="isSuper"/>
    </ViewDialog>

    <ViewDialog v-model="isShowPasswordEditor" title="Изменение пароля">
      <InputPassword @save="savePassword" ref="InputPassword"/>
    </ViewDialog>
  </div>
</template>

<style scoped>

</style>
