<template>
    <v-app-bar
            v-if="$route.path !== '/login'"
            app
            flat
            class="app-bar"
            :style="appTitleStyle"
    >
        <LogoBlock/>

        <h3 v-if="title" class="ml-2" style="line-height: 1;">{{ title }}</h3>

        <slot></slot>

        <v-spacer/>

      <v-btn
        v-if="$store.persist.d.role === 'super' || $store.persist.d.role === 'dev'"
        icon
        class="ml-2"
        @click="openControlCompaniesPanel"
      >
        <v-icon>mdi-star</v-icon>
      </v-btn>

        <v-btn
          v-if="['admin', 'super', 'dev'].includes($store.persist.d.role) && $store.persist.d.companyId"
          icon
          class="ml-2"
          @click="openCompanyProfile"
        >
          <v-icon>mdi-office-building</v-icon>
        </v-btn>

      <v-btn
        v-if="$store.main.s.app === 'admin' || $store.persist.d.isDefinedInnerOuter"
        icon
        class="ml-2"
        @click="openProfile"
      >
        <v-icon>mdi-account</v-icon>
        <!--          <v-icon>mdi-cog</v-icon>-->
      </v-btn>

        <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                color="primary"
        />

      <v-dialog
          v-model="$store.main.d.isShowControlCompaniesPanel"
          scrollable
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          max-width="680"
      >
        <v-card tile class="app-dialog">
          <v-toolbar
              flat
              class="toolbar"
              dense
          >
            <v-toolbar-title>Панель управления компаниями</v-toolbar-title>
            <v-spacer/>
            <slot name="toolbar"></slot>
            <v-btn
                icon
                @click="closeControlCompaniesPanel"
                class="toolbar__button-close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <PageControlCompaniesPanel/>

        </v-card>
      </v-dialog>

      <v-dialog
        v-model="$store.main.d.isShowCompanyProfile"
        scrollable
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="680"
      >
        <v-card tile class="app-dialog">
          <v-toolbar
            flat
            class="toolbar"
            dense
          >
            <v-toolbar-title>Профиль компании</v-toolbar-title>
            <v-spacer/>
            <slot name="toolbar"></slot>
            <v-btn
              icon
              @click="closeCompanyProfile"
              class="toolbar__button-close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <PageCompanyProfile/>

        </v-card>
      </v-dialog>

      <v-dialog
        v-model="$store.main.d.isShowProfile"
        scrollable
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="680"
      >
        <v-card tile class="app-dialog">
          <v-toolbar
            flat
            class="toolbar"
            dense
          >
            <v-toolbar-title>Профиль</v-toolbar-title>
            <v-spacer/>
            <slot name="toolbar"></slot>
            <v-btn
              icon
              @click="closeProfile"
              class="toolbar__button-close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <PageProfile/>

        </v-card>
      </v-dialog>

    </v-app-bar>
</template>

<script>
import LogoBlock from "@/ui/components/LogoBlock.vue";
import PageProfile from "../pages/PageProfile.vue";
import PageCompanyProfile from "@/ui/pages/PageCompanyProfile.vue";
import PageControlCompaniesPanel from "@/ui/pages/PageControlCompaniesPanel.vue";

export default {
  name: 'AppBar',
  components: {PageControlCompaniesPanel, PageCompanyProfile, PageProfile, LogoBlock},
  data: () => ({
    showToolbar: false,
  }),
  watch: {
    '$route'() {
      this.checkHash();
    }
  },
  computed: {
    appTitleStyle() {
      return {
        opacity: this.showToolbar ? 1 : 0,
        'overflow-x': this.$vuetify.breakpoint.mdAndUp ? 'none' : 'auto',
        'overflow-y': 'hidden',
      };
    },
    loading() {
      return false;
    },
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    checkHash() {
      this.$store.main.d.isShowProfile = window.location.hash === '#profile';
    },
    openProfile() {
      window.location.hash = '#profile';
    },
    openCompanyProfile() {
      this.$store.main.d.isShowCompanyProfile = true;
    },
    openControlCompaniesPanel() {
      this.$store.main.d.isShowControlCompaniesPanel = true;
    },
    closeProfile() {
      window.location.hash = '';
    },
    closeCompanyProfile() {
      this.$store.main.d.isShowCompanyProfile = false;
    },
    closeControlCompaniesPanel() {
      this.$store.main.d.isShowControlCompaniesPanel = false;
    },
  },
  mounted() {
    this.showToolbar = true;

    window.addEventListener('hashchange', this.checkHash);
    this.checkHash();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.checkHash);
  },
}
</script>

<style scoped lang="scss">
.app-bar {
  transition: opacity .3s;
}

.link.v-btn--active {
  color: var(--v-primary-base)
}

.v-btn.dev {
  margin-top: -25px;
  margin-left: -50px;
  margin-right: 10px;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background: #fff;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
